import { graphql, navigate } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import { Content, Footer, FooterLinkBack, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';
import styles from '../sass/components/answer.module.scss';
import SvgArrowLeft from '../svg/arrowLeft';
import removeHtmlTags from '../utils/remove-html-tags';
import removePTags from '../utils/remove-p-tags';
import getString from '../utils/get-string';

export default class Answer extends React.Component {
  render() {
    if (typeof window !== `undefined` && !this.props.location.state) {
      const quizUrl = this.props.location.pathname.split('answer')[0]
      navigate(`${quizUrl}quiz`)
      return <Layout />
    }

    const currentLesson = this.props.data.datoCmsLesson

    const LargeLink = () => {
      const currentLessonIndex = this.props.data.allDatoCmsLesson.edges.findIndex(
        ({ node }) => node.id === currentLesson.id
      )
      const nextLesson = this.props.data.allDatoCmsLesson.edges[
        currentLessonIndex + 1
      ]

      const NextLessonLink = () => (
        <FooterLinkNext to={`/lessons#${nextLesson.node.url}`}>
          {getString(this.props.data.strings, "11220982")}
        </FooterLinkNext>
      )

      const BackToHomepageLink = () => (
        <FooterLinkNext to="/lessons">{getString(this.props.data.strings, "11220979")}</FooterLinkNext>
      )

      return nextLesson ? <NextLessonLink /> : <BackToHomepageLink />
    }

    const answerIsRight =
      typeof window !== `undefined` && this.props.location.state.answerIsRight
    const backLink =
      typeof window !== `undefined` && this.props.location.state.backLink
    const pageData = this.props.data.datoCmsAnswerPage

    return (
      <Layout>
        <Seo
          title={
            answerIsRight
              ? removeHtmlTags(pageData.rightTitle)
              : removeHtmlTags(pageData.wrongTitle)
          }
          description={answerIsRight ? pageData.rightText : pageData.wrongText}
        />

        <div className={styles.answer}>
          <Page type={answerIsRight ? 'success' : 'error'}>
            <Header closeUrl={`/lessons#${currentLesson.url}`}>
              {currentLesson.number}
            </Header>
            <Content>
              <h1
                className={`growing ${styles.answer__title}`}
                dangerouslySetInnerHTML={{
                  __html: answerIsRight
                    ? removePTags(pageData.rightTitle)
                    : removePTags(pageData.wrongTitle),
                }}
              />
              <div className="page-text-container">
                <div
                  className={`h6 ${styles.answer__text}`}
                  dangerouslySetInnerHTML={{
                    __html: answerIsRight
                      ? pageData.rightText
                      : pageData.wrongText,
                  }}
                />
              </div>
            </Content>
            <Footer>
              <LargeLink />
              <FooterLinkBack
                to={
                  backLink ||
                  `/${currentLesson.url}${answerIsRight ? '' : '/quiz'}`
                }
              >
                <SvgArrowLeft />
                {answerIsRight ? getString(this.props.data.strings, "11220983") : getString(this.props.data.strings, "11257662")}
              </FooterLinkBack>
            </Footer>
          </Page>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String!) {
    datoCmsAnswerPage {
      rightTitle
      wrongTitle
      rightText
      wrongText
    }

    datoCmsLesson(id: { eq: $id }) {
      id
      number
      url
    }

    allDatoCmsLesson(sort: { fields: position, order: ASC }) {
      edges {
        node {
          id
          url
        }
      }
    }

    strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11220982", "11220979", "11220983", "11257662"]}}) {
      edges {
        node {
          string
          original
          originalId
        }
      }
    }
  }
`
